import Analytics from './analytics'
import getCurrentIp from './../services/getCurrentIp'

const registerUserProperties = ({ properties, analytics }) => {
  analytics.setUserId(properties.userId)
  analytics.setUserProperties(properties)
  analytics.logEvent('WEB_INSTITUICOES_LOGGED_IN')
}

const registerUserPropertiesToAnalytics = () => {
  const userProperties = {
    establishmentId: window.localStorage.getItem('Establishment-ID-Vida-Connect'),
    userType: window.localStorage.getItem('Type-Vida-Connect'),
    environment: process.env.REACT_APP_ENVIRONMENT || 'local'
  }

  return getCurrentIp()
    .then(ip => {
      userProperties['ipAddress'] = ip
      console.log(userProperties)
      registerUserProperties({ properties: userProperties, analytics: Analytics })
    })
    .catch(() => registerUserProperties({ properties: userProperties, analytics: Analytics }))
}

export default registerUserPropertiesToAnalytics
